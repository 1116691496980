import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AddPoints(props) {
  const history = useHistory();
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState([]);
  const token = localStorage.getItem("token");
  const [newPoints, setNewPoints] = useState({
    contactid: "",
    points: "",
  });

  function handleChange(e) {
    setNewPoints({ ...newPoints, [e.target.name]: e.target.value });
  }
  function handleSubmit(e) {
    e.preventDefault();

    const headers = {
      headers: { authorization: token },
    };
    axios
      .post(
        "https://mm010221.herokuapp.com/refer/addPoints",
        {
          contactid: newPoints.contactid,
          points: newPoints.number,
        },
        headers
      )
      .then((res) => {
        // localStorage.setItem("token", res.data.token);
        setMsg(res.data.newPoints);
        console.log(res);
        history.push("/admin/addPoints");
        setNewPoints({
          ...newPoints,
          contactid: "",
          points: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data);
          console.log(err.response);
        }
      });
  }
  return (
    <div>
      <div className="formWrapper w-full md:w-1/3 mx-auto px-4">
        {msg ? (
          <span className="bg-green-800 text-white px-4 py-2 rounded-md mt-1 block">
            {msg}
          </span>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <label htmlFor="contactid">
            Contact ID
            <input
              type="text"
              name="contactid"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newPoints.contactid}
            />
          </label>
          {errors.contactid ? (
            <span className="bg-red-400 text-white px-4 py-2 rounded-md mt-1 block">
              {errors.contactid}
            </span>
          ) : (
            ""
          )}

          <label htmlFor="points">
            Points
            <input
              type="number"
              name="points"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newPoints.points}
            />
          </label>
          <input
            type="submit"
            value="Add Raffle"
            className="px-6 py-2 mt-2 rounded-md bg-red-400 text-white hover:bg-red-800 cursor-pointer"
          />
        </form>
      </div>
    </div>
  );
}

export default AddPoints;
