import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Spinner from "./Parts/Spinner";

function RaffleList(props) {
  const [raffle, setRaffle] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://mm010221.herokuapp.com/raffle/")
      .then((res) => {
        setRaffle(res.data.reverse());
        setLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Card = (props) => {
    return (
      <div className="card text-center mb-4">
        <div className="cardBody mx-auto">
          <img
            src={props.imgURL}
            className="mx-auto w-32 h-32 shadow-2xl border-4 border-white dark:border-red-500"
            alt={props.name}
          />
          <div className="content shadow-lg px-6 pt-12 pb-6 -mt-10 border border-red-50 rounded-md dark:border-red-500">
            <h3 className="font-semibold md:min-h-cardHeader align-middle text-lg text-red-400 py-3 border-b border-red-400">
              {props.title}
            </h3>
            <div className="body py-2 md:min-h-cardContent text-sm leading-6">
              {props.desc}
            </div>
            <div className="cardFooter w-full py-4 border-t border-gray-200 text-black">
              <span className="bg-yellow-400 rounded-md border border-black drop-shadow-md px-5 py-2 inline-block">
                {props.number}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {isLoading ? <Spinner /> : ""}
      <div className="w-1/3 mx-auto">
        <img src="/images/congrats.png" alt="congratulations" />
      </div>
      <div className="cardWrap grid md:grid-cols-2 lg:grid-cols-4 gap-2">
        {raffle.map((obj) => {
          return (
            <Card
              key={obj._id}
              imgURL={obj.imgURL}
              title={obj.title}
              desc={obj.desc}
              number={obj.number}
            />
          );
        })}
      </div>
    </div>
  );
}

export default RaffleList;