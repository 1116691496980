import React, { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

function WhatsApp(props) {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    let raw = e.target.value;
    let rawClean = raw.split(" ").join(""); // clean whitespaces
    let length = rawClean.length; //check the length of phone number
    //check number starts with 8 or 9
    if (/^[8|9]/.test(rawClean)) {
        setPhone(rawClean); 
        if(length>8){ 
          setError("Enter only 8 numbers")
        }else{
          setError("");
        }
    } else if (e.target.value === "") { //check empty
        setError("");
        setPhone("")
    } else {
      setPhone(rawClean);
      setError("Phone number should start with 8 or 9");
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      window.open("https://api.whatsapp.com/send?phone=65" + phone);
    }
  };
  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="name w-full mb-6 px-3">
        <span className="text-xs text-yellow-400">{error}</span>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 font-semibold sm:text-sm">+65</span>
          </div>
          <input
            placeholder="Enter phone number"
            type="tel"
            name="phone"
            onChange={handleChange}
            onKeyPress={handleEnter}
            value={phone ? phone : ""}
            className="pl-10 dark:text-gray-700 pr-12 border-gray-400 border focus:border-gray-500 bg-white appearance-none block w-full focus:outline-none shadow-inner leading-tight rounded-md py-3 px-4"
            autoFocus
          />
        </div>
        <a
          href={`https://api.whatsapp.com/send?phone=65` + phone}
          target="_blank"
          rel="noreferrer"
          className="w-full py-2 mt-4 text-center block rounded-md bg-red-800 text-white cursor-pointer hover:bg-white hover:text-red-800 hover:border-red-800 border"
        >
          <FaExternalLinkAlt className="inline-block" /> Open WhatsApp
        </a>
      </div>
    </div>
  );
}

export default WhatsApp;
