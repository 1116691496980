import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import InputMask from "react-input-mask";

function Raffle(props) {
    const [raffle,setRaffle] = useState("");
    const [doneChecking, setChecking] = useState(true);
    const [ticket, setTicket] = useState("");
    const [error, setError] = useState("");
    let [result, setResult] = useState(null);
    // let result = null
    const handleChange = (e) => {
        let raw = e.target.value;
        let rawUpper = raw.toUpperCase();
        console.log(rawUpper)
        setTicket(rawUpper)
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            if(ticket===""){
                setError("စစ်ဆေးနိုင်ရန် သင့်လက်မှတ်ကို ရိုက်ထည့်ပါ။")
            }else{
                let checking  = raffle.filter((raf)=>raf.number === ticket);
                setResult(checking);
                setError("")
                setChecking(true);
                setTicket("")
            }
          
        }
      };

    const handleClick =()=>{
        if(ticket===""){
            setError("စစ်ဆေးနိုင်ရန် သင့်လက်မှတ်ကို ရိုက်ထည့်ပါ။")
        }else{
            setChecking(false);
            let checking  = raffle.filter((raf)=>raf.number === ticket);
            setResult(checking);
            setError("")
            setChecking(true)
            setTicket("")
        }
        
    }

    useEffect(() => {
        axios
          .get("https://mm010221.herokuapp.com/raffle/")
          .then((res) => {
            setRaffle(res.data.reverse());
            // setLoading(false);
            console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      },  []);

    return (
      <div className="max-w-2xl mx-auto p-6">
        <Helmet>
          <title>Check Raffle Ticket | Burma Spring Revolution 2021</title>
          <meta
            name="description"
            content="ယုံကြည်ရာ ခြေလှမ်းများအတွက် ဆုမဲလက်ဆောင် စစ်ဆေးရန်"
          />
        </Helmet>
        <h3 className="font-bold text-2xl text-center">
          Check Your Raffle Ticket Here
        </h3>
        <div className="text-center">
          <span className="underline text-red-800">
            <a href="/raffle">Check Full Winners List</a>
          </span>
        </div>
        {/* <span className="text-xs bg-yellow-600 text-white px-2 py-2 rounded-md inline-block my-2">မြန်မာစံတော်ချိန် ည (၇) နာရီမှ စတင် မဲဖောက်ပေးမှာ ဖြစ်တဲ့အတွက် ည ၇ နာရီ နောက်ပိုင်းမှသာ စတင် စစ်ဆေးနိုင်ပါမည်။</span> */}
        <div className="name w-full mb-6 px-3">
          <span className="text-xs text-yellow-400">{error}</span>
          <div className="mt-1 relative rounded-md shadow-sm">
            <InputMask
              mask="***-***-****-******"
              maskChar=""
              placeholder="ABC-000-EFGH-A12345"
              type="text"
              name="ticket"
              onChange={handleChange}
              onKeyPress={handleEnter}
              value={ticket ? ticket : ""}
              className="pl-10 dark:text-gray-700 pr-12 border-gray-400 border focus:border-gray-500 bg-white appearance-none block w-full focus:outline-none shadow-inner leading-tight rounded-md py-3 px-4"
              autoFocus
            />
          </div>
          <button
            onClick={handleClick}
            className="text-center bg-red-800 w-full mt-2 py-2 rounded-md text-white font-semibold"
          >
            {doneChecking ? "Check" : "Checking..."}
          </button>
          {/* <a
                href={`https://api.whatsapp.com/send?phone=65` + phone}
                target="_blank"
                rel="noreferrer"
                className="w-full py-2 mt-4 text-center block rounded-md bg-red-800 text-white cursor-pointer hover:bg-white hover:text-red-800 hover:border-red-800 border"
                >
                <FaExternalLinkAlt className="inline-block" /> Open WhatsApp
                </a> */}
          <span
            className={`${
              result && doneChecking && result.length === 0 ? "block" : "hidden"
            } bg-red-600 text-white px-6 py-4 rounded-md mt-4`}
          >
            ဝမ်းနည်းပါတယ်။ သင့် ticket က ကံစမ်းမဲ မပေါက်ပါ။ <br />
            ယုံကြည်ရာ ခြေတစ်လှမ်းအတွက် ပါဝင်လှူဒါန်းတဲ့အတွက် ကျေးဇူးတင်ပါတယ်။
          </span>

          {result &&
            result.map((res) => {
              return (
                <div className="pt-5 text-center">
                  <span className="bg-green-600 px-6 py-4 inline-block mb-4 rounded-md text-white">
                    ဂုဏ်ယူပါတယ်။ သင်ရဲ့ ticket က အောက်ပါ ဆုမဲကို ကံထူးပါတယ်။
                    <br />
                    ယုံကြည်ရာ ခြေတစ်လှမ်းအတွက် ပါဝင်လှူဒါန်းတဲ့အတွက်
                    ကျေးဇူးတင်ပါတယ်။
                  </span>
                  <div className="font-bold text-center mb-1">{res.title}</div>
                  <div className="mb-2">
                    <span className="font-light font-serif text-sm">
                      {res.desc}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="bg-yellow-400 rounded-md border border-black drop-shadow-md px-5 py-2 inline-block">
                      {res.number}
                    </span>
                  </div>
                  <div className="mb-6">
                    <img src={res.imgURL} className="block mx-auto" alt="" />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
}

export default Raffle;