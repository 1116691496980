import React, { useContext, useEffect, useState } from "react";
import Interweave from "interweave";
import Spinner from "./Parts/Spinner";
import Campaign from "./Parts/Campaign";
import { ChangeLang, Lang } from "../components/Parts/ChangeLang";
import { Helmet } from "react-helmet-async";
import { client } from "./Parts/Contentful";
import { BsInfoCircle } from "react-icons/bs";
require("dotenv").config();

function Home(props) {
  const [isLoading, setLoading] = useState(true);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("2022");
  let i = 0;
  let language = useContext(ChangeLang)[0];
  let currentLang = Lang[language];

  const [post, setPost] = useState([]);

  const postFilter = post.filter(
    (item) =>
      item.fields.eventDate.split(" ")[1] === month &&
      item.fields.date.split("-").shift() === year
  );

  function handleChange(e) {
    setMonth(e.target.value);
  }

  function handleChangeYear(e) {
    setYear(e.target.value);
  }

  // console.log(post.fields.eventDate.indexOf("-"));

  useEffect(() => {
    client
      .getEntries({
        locale: `${currentLang.lang}`,
        content_type: "blogPost",
        order: "-fields.date",
      })
      .then((response) => {
        setPost(response.items);
        let eventDate = response.items[0].fields.eventDate;
        setMonth(eventDate.split(" ")[1]);
        setLoading(false);
        console.log(month);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang]);

  const Left = (props) => {
    return (
      <div className="flex flex-row-reverse md:contents">
        <div className="bg-red-500 col-start-1 col-end-5 p-4 mr-1 md:mr-5 rounded-xl my-4 ml-auto shadow-md md:max-w-full">
          <h3 className="font-semibold text-md md:text-lg mb-1">
            {props.title}
          </h3>
          <div className="leading-6 text-sm">
            <Interweave tagName="div" content={props.entry} />
          </div>
        </div>
        <div className="col-start-5 col-end-6 md:mx-auto relative mr-1 md:mr-10">
          <div className="h-full w-16 flex items-center justify-center">
            <div className="h-full w-1 bg-red-800 pointer-events-none"></div>
          </div>
          <div className="flex flex-col justify-center w-16 h-16 absolute top-1/2 -mt-3 rounded-full bg-red-500 shadow text-center">
            <span>{props.date}</span>
          </div>
        </div>
      </div>
    );
  };

  const Right = (props) => {
    return (
      <div className="flex md:contents">
        <div className="col-start-5 col-end-6 mr-1 md:mr-10 md:mx-auto relative leftWrapper">
          <div className="h-full w-16 flex items-center justify-center">
            <div className="h-full w-1 bg-red-800 pointer-events-none"></div>
          </div>
          <div className="flex flex-col justify-center w-16 h-16 absolute top-1/2 -mt-3 rounded-full bg-red-500 shadow text-center">
            <span>{props.date}</span>
          </div>
        </div>
        <div className="bg-red-500 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
          <h3 className="font-semibold text-md md:text-lg mb-1">
            {props.title}
          </h3>
          <ul className="leading-6 text-sm">
            <Interweave content={props.entry} />
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div className="container px-4 mx-auto">
      <Helmet>
        <title>Burma Spring Revolution 2021 | Popular Events</title>
        <meta
          name="description"
          content="010221.org is aimed to keep popular events during burma spring revolution 2021 (Feb 1, 2021 Coup), to list down military-owned businesses to avoid and Social Punishment for persons who involved in military coup in any way."
        />
        <meta
          name="keywords"
          content="Feb 1 coup, myanmar spring revolution, myanmar coup, myanmar junta"
        />
      </Helmet>
      {/* <h1 className="title text-center">01 Feb 21</h1> */}

      {/* <GetOS id="1474835114" /> */}
      <div className="mx-auto">
        <Campaign />
        <div className="grid lg:grid-cols-2 gap-2 md:w-2/3 mx-auto">
          <div className="month">
            <select
              name="month"
              id="month"
              className="w-full border border-gray-300 text-gray-600 focus:outline-none px-4 py-2 my-2 h-10 rounded-md"
              onChange={handleChange}
              value={month}
            >
              <option value="Jan">Jan</option>
              <option value="Feb">Feb</option>
              <option value="Mar">Mar</option>
              <option value="Apr">Apr</option>
              <option value="May">May</option>
              <option value="Jun">Jun</option>
              <option value="Jul">Jul</option>
              <option value="Aug">Aug</option>
              <option value="Sep">Sep</option>
              <option value="Oct">Oct</option>
              <option value="Nov">Nov</option>
              <option value="Dec">Dec</option>
            </select>
          </div>
          <div className="month">
            <select
              name="year"
              id="year"
              className="w-full border border-gray-300 text-gray-600 focus:outline-none px-4 py-2 my-2 h-10 rounded-md"
              value={year}
              onChange={handleChangeYear}
            >
              <option value="2022">2022</option>
              <option value="2021">2021</option>
            </select>
          </div>
        </div>
        {postFilter.length === 0 && !isLoading ? (
          <div className="mx-auto text-center mt-4">
            <span>
              <div
                class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <BsInfoCircle className="inline" />
                <span className="px-4 rounded">
                  Information are not available for the selected month. Please
                  choose different month or check back later.
                </span>{" "}
                <br />
                <span className="px-4 rounded">
                  You can also help us by sending information to{" "}
                  <a
                    href="mailto:contact@010221.org"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    contact@010221.org
                  </a>
                </span>
              </div>
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
          {isLoading ? <Spinner /> : ""}
          {/* left */}

          {postFilter &&
            postFilter.map((obj) => {
              if (obj.fields.left) {
                return (
                  <Left
                    key={obj.fields.slug}
                    title={obj.fields.title}
                    entry={obj.fields.excerpt}
                    date={obj.fields.eventDate}
                  />
                );
              } else {
                return (
                  <Right
                    key={obj.fields.slug}
                    title={obj.fields.title}
                    entry={obj.fields.excerpt}
                    date={obj.fields.eventDate}
                  />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}

export default Home;
