import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

function UpdateOrder(props) {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState([]);
  const token = localStorage.getItem("token");
  const [isRedirect, setRedirect] = useState(false);
  const [newPoints, setNewPoints] = useState({
    name: "",
    contact_id: "",
    points: 0,
  });

  function handleChange(e) {
    setNewPoints({ ...newPoints, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const headers = {
      headers: { authorization: token },
    };
    axios
      .post(
        "https://mm010221.herokuapp.com/order/update/" + id,
        {
          name: newPoints.name,
          contact_id: newPoints.contact_id,
          points: newPoints.points,
        },
        headers
      )
      .then((res) => {
        // localStorage.setItem("token", res.data.token);
        setMsg(res.data.newPoints);
        console.log(res);
        history.push("/admin/orderlist");
        setNewPoints({
          ...newPoints,
          name: "",
          contact_id: "",
          points: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data);
          console.log(err.response);
        }
      });
  }

  function fetchOrder() {
    axios
      .get("https://mm010221.herokuapp.com/order/" + id)
      .then((res) => {
        // let newres = Object.entries(res);
        setNewPoints(res.data);
        setLoading(false);
        if (res.data.length === 0) {
          setRedirect(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchOrder();
  }, []);

  return (
    <div>
      <div className="formWrapper w-full md:w-1/3 mx-auto px-4">
        {msg ? (
          <span className="bg-green-800 text-white px-4 py-2 rounded-md mt-1 block">
            {msg}
          </span>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <label htmlFor="contact_id">
            Contact ID
            <input
              type="text"
              name="contact_id"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newPoints.contact_id}
              readOnly
            />
          </label>
          {errors.contact_id ? (
            <span className="bg-red-400 text-white px-4 py-2 rounded-md mt-1 block">
              {errors.contact_id}
            </span>
          ) : (
            ""
          )}

          <label htmlFor="points">
            Points
            <input
              type="number"
              name="points"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newPoints.points}
            />
          </label>
          <input
            type="submit"
            value="Confirm"
            className="px-6 py-2 mt-2 rounded-md bg-red-400 text-white hover:bg-red-800 cursor-pointer"
          />
        </form>
      </div>
    </div>
  );
}

export default UpdateOrder;
