import React, { useEffect, useState } from "react";
import { FcCalendar, FcClock } from "react-icons/fc";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import AddToCalendar from "@culturehq/add-to-calendar";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import { client } from "./Contentful";
import { HCard } from "./HCard";
import { FullDate, dateToTime } from "./Dates";
import { FaLongArrowAltRight } from "react-icons/fa";

function Campaign(props) {
  const [isLoading, setLoading] = useState(true);
  const [campaigns, setCampaign] = useState([]);
  const [events, setEvent] = useState({
    name: "",
    details: "",
    location: null,
    startsAt: null,
    endsAt: null,
  });

  const Card = (props) => {
    return (
      <div className="card-wrapper px-2 mb-2 grid md:grid-cols-2 p-4 rounded-md dark:hover:bg-gray-900 border border-red-400">
        <Link to={`/campaign/${props.slug}`}>
          <div className="img mx-auto px-2">
            <img src={props.artwork} alt="" className="w-60 h-auto" />
          </div>
        </Link>

        <div className="mb-2">
          <Link to={`/campaign/${props.slug}`}>
            <div className="text-xl text-center font-bold text-red-400 mt-4 mb-2">
              {props.title}
            </div>
          </Link>

          <div className="slogan text-center text-xs text-gray-500 font-medium leading-9 mb-2">
            {props.slogan}
          </div>
          <div className="dateTime text-sm mb-2">
            <span className="date font-extralight">
              <FcCalendar className="inline-block mr-1" />
              {props.date === props.endDate
                ? props.date
                : props.date + " - " + props.endDate}
            </span>
            <br />
            <span className="time font-extralight">
              <FcClock className="inline-block mr-1" />
              {props.time} - {props.endTime}
            </span>
            {events && (
              <AddToCalendar
                event={events}
                children="ရက်စွဲ မှတ်ထားမယ်"
                filename="010221.org_events.ics"
              />
            )}
          </div>
          <div className="city"></div>
          <div className="desc px-4 text-sm leading-7">
            {documentToReactComponents(props.excerpt)}
          </div>
        </div>
      </div>
    );
  };

  const CheckingEmpty = (props) => {
    return (
      <div
        className="px-4 py-3 leading-normal mt-5 text-yellow-700 bg-yellow-100 rounded-lg w-full mx-auto"
        role="alert"
      >
        <span>Stay Tuned with us. More Strikes are coming.</span>
        <Link
          to="/campaigns/completed"
          className="inline-block text-sm text-red-400 font-normal hover:underline mb-4"
        >
          အရင်သပိတ်တွေ ပြန်ကြည့်မယ်{" "}
          <FaLongArrowAltRight className="inline-block" />
        </Link>
      </div>
    );
  };
  useEffect(() => {
    const d = new Date();
    const today = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 10);

    client
      .getEntries({
        content_type: "campaign",
        "fields.dateTime[gt]": today,
        order: "sys.createdAt",
      })
      .then((response) => {
        setCampaign(response.items);
        setLoading(false);
        if (response.items.length > 0) {
          setEvent({
            ...events,
            name: response.items[0].fields.title,
            details: response.items[0].fields.slogan,
            startsAt: response.items[0].fields.dateTime,
            endsAt: response.items[0].fields.endTime,
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid md:grid-cols-7 md:gap-4">
      <div
        className={`featured md:col-span-4 ${
          campaigns.length === 0 ? "hidden" : ""
        }`}
      >
        {isLoading ? <Spinner /> : ""}
        {campaigns.slice(0, 1).map((obj) => {
          const localDate = new Date(obj.fields.dateTime);
          const endLocalDate = new Date(obj.fields.endTime);
          const date = FullDate(localDate, "2-digit", "short", "numeric");
          const time = dateToTime(localDate);
          const endDate = FullDate(endLocalDate, "2-digit", "short", "numeric");
          const endTime = dateToTime(endLocalDate);
          return (
            <Card
              key={obj.sys.id}
              artwork={obj.fields.artwork.fields.file.url}
              title={obj.fields.title}
              slug={obj.fields.slug}
              date={date}
              time={time}
              endDate={endDate}
              endTime={endTime}
              excerpt={obj.fields.excerpt}
              desc={obj.fields.description}
            />
          );
        })}
        <Link
          to="/campaigns/completed"
          className="text-sm text-red-400 font-normal hover:underline float-right mb-4"
        >
          အရင်သပိတ်တွေ ပြန်ကြည့်မယ်{" "}
          <FaLongArrowAltRight className="inline-block" />
        </Link>
      </div>
      <div className="upcoming md:col-span-3">
        <div className="wrapper">
          <h3 className="text-red-400 font-extrabold text-lg text-center md:text-left">
            Upcoming Strikes
          </h3>
          {campaigns.slice(1).length === 0 ? <CheckingEmpty /> : ""}
          {campaigns.slice(1).map((obj) => {
            const localDate = new Date(obj.fields.dateTime);
            const endLocalDate = new Date(obj.fields.endTime);
            const date = FullDate(localDate, "2-digit", "short", "numeric");
            const time = dateToTime(localDate);
            const endDate = FullDate(
              endLocalDate,
              "2-digit",
              "short",
              "numeric"
            );
            const endTime = dateToTime(endLocalDate);
            return (
              <HCard
                key={obj.sys.id}
                artwork={obj.fields.artwork.fields.file.url}
                slug={obj.fields.slug}
                title={obj.fields.title}
                date={date}
                time={time}
                endDate={endDate}
                endTime={endTime}
                eventStarts={obj.fields.dateTime}
                eventEnds={obj.fields.endTime}
                events="true"
                slogan={obj.fields.slogan}
                excerpt={obj.fields.excerpt}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Campaign;
