import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "../Parts/Spinner";

function PointsOrder(props) {
  const [orders, setOrders] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://mm010221.herokuapp.com/order/")
      .then((res) => {
        setOrders(res.data.reverse());
        // setLoading(false);
        console.log(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <table className="table-auto">
        <thead>
          <tr>
            <th>Name</th>
            <th>Contact ID</th>
            <th>Points</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <Spinner />}
          {!isLoading && orders.length < 1 && <tr>No Orders found.</tr>}
          {orders &&
            orders.map((order) => {
              return (
                <tr>
                  <td>{order.name}</td>
                  <td>{order.contact_id}</td>
                  <td>{order.points}</td>
                  <td>
                    <a href={`/admin/order/edit/${order._id}`}>Edit</a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default PointsOrder;
