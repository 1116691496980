import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

function AddBoycott(props) {
  const history = useHistory();
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState([]);
  const token = localStorage.getItem("token");
  const [newBusiness, setNewBusiness] = useState({
    product: "",
    mmName: "",
    industry: "",
    popularity: "",
    owned: "",
    owner: "",
    detail: "",
    related: "",
  });

  function handleChange(e) {
    setNewBusiness({ ...newBusiness, [e.target.name]: e.target.value });
  }
  console.log(newBusiness.owned);
  function handleSubmit(e) {
    e.preventDefault();

    const headers = {
      headers: { authorization: token },
    };
    axios
      .post(
        "https://mm010221.herokuapp.com/business/add",
        {
          product: newBusiness.product,
          mmName: newBusiness.mmName,
          industry: newBusiness.industry,
          popularity: newBusiness.popularity,
          owned: newBusiness.owned,
          owner: newBusiness.owner,
          detail: newBusiness.detail,
          related: newBusiness.related,
        },
        headers
      )
      .then((res) => {
        // localStorage.setItem("token", res.data.token);
        setMsg(res.data.business);
        console.log(res);
        history.push("/admin/addBoycott");
        setNewBusiness({
          ...newBusiness,
          product: "",
          mmName: "",
          industry: "",
          popularity: "",
          owned: "",
          owner: "",
          detail: "",
          related: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data);
          console.log(err.response);
        }
      });
  }
  return (
    <div>
      <div className="formWrapper w-full md:w-1/3 mx-auto px-4">
        {msg ? (
          <span className="bg-green-800 text-white px-4 py-2 rounded-md mt-1 block">
            {msg}
          </span>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <label htmlFor="product">
            Product
            <input
              type="text"
              name="product"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newBusiness.product}
            />
          </label>
          <label htmlFor="mmName">
            MM Name
            <input
              type="text"
              name="mmName"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newBusiness.mmName}
            />
          </label>
          {errors.product ? (
            <span className="bg-red-400 text-white px-4 py-2 rounded-md mt-1 block">
              {errors.product}
            </span>
          ) : (
            ""
          )}
          <label htmlFor="detail">
            Detail
            <textarea
              name="detail"
              id="detail"
              cols="30"
              rows="10"
              className="w-full border focus:outline-none px-2 rounded-md"
              onChange={handleChange}
              value={newBusiness.detail}
            ></textarea>
          </label>
          <label htmlFor="industry">
            Industry
            <input
              type="text"
              name="industry"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newBusiness.industry}
            />
          </label>
          <label htmlFor="popularity">
            Popularity
            <input
              type="text"
              name="popularity"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newBusiness.popularity}
            />
          </label>
          <label htmlFor="owned">
            Owned
            <select className="w-full" name="owned" onChange={handleChange}>
              <option value="military">Military</option>
              <option value="private">Private</option>
            </select>
          </label>
          <label htmlFor="owner">
            Owner
            <input
              type="text"
              name="owner"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newBusiness.owner}
            />
          </label>
          <label htmlFor="related">
            Related
            <textarea
              name="related"
              id="related"
              cols="30"
              rows="10"
              className="w-full border focus:outline-none px-2 rounded-md"
              onChange={handleChange}
              value={newBusiness.related}
            ></textarea>
          </label>
          <input
            type="submit"
            value="Add New Boycott"
            className="px-6 py-2 mt-2 rounded-md bg-red-400 text-white hover:bg-red-800 cursor-pointer"
          />
        </form>
      </div>
    </div>
  );
}

export default AddBoycott;
