import AddToCalendar from "@culturehq/add-to-calendar";
import { useState } from "react";
import { FcCalendar, FcClock } from "react-icons/fc";
import { Link } from "react-router-dom";

export const HCard = (props) => {
  const [events, setEvent] = useState({
    name: props.title,
    details: props.slogan,
    location: null,
    startsAt: props.eventStarts,
    endsAt: props.eventEnds,
  });
  const dateParts = props.date.split(" ");
  return (
    <div className="wrapper grid grid-cols-12 gap-2 border border-l-6 border-red-400 mb-2 p-2 rounded-md">
      <span className="col-span-2 date border text-center">
        <div className="bg-red-400 py-1">
          <span className="text-sm text-white">
            {dateParts[2] + " " + dateParts[3]}
          </span>
        </div>
        <span className="text-2xl font-black mt-4 inline-block">
          {dateParts[1]}
        </span>
        <br />
        <span className="text-sm">{dateParts[0].substring(0, 3)}</span>
      </span>
      <div className="col-span-3 mx-auto">
        <Link to={`/campaign/${props.slug}`}>
          <img
            src={props.artwork}
            alt=""
            className="w-28 h-28 object-contain"
          />
        </Link>
      </div>

      <div className="font-semibold col-span-7 leading-7">
        <Link to={`/campaign/${props.slug}`}>
          <div className="text-lg text-red-400 mb-2">{props.title}</div>
        </Link>

        <div className="dateTime text-sm mb-2">
          <span className="date font-extralight">
            <FcCalendar className="inline-block mr-1" />
            {props.date === props.endDate
              ? props.date
              : props.date + " - " + props.endDate}
          </span>
          <br />
          <span className="time font-extralight">
            <FcClock className="inline-block mr-1" />
            {props.time} - {props.endTime}
          </span>
          {props.events === "true" && (
            <AddToCalendar
              event={events}
              children="ရက်စွဲ မှတ်ထားမယ်"
              filename="010221.org_events.ics"
            />
          )}
        </div>
        <div className="desc text-xs">{props.slogan}</div>
      </div>
    </div>
  );
};
