import axios from 'axios';
import React, { useEffect, useState } from 'react';

function RaffleList(props) {
    const [raffle,setRaffle] = useState([])

    useEffect(() => {
        axios
          .get("https://mm010221.herokuapp.com/raffle/")
          .then((res) => {
            setRaffle(res.data.reverse());
            // setLoading(false);
            console.log(res.data)
          })
          .catch((err) => {
            console.log(err);
          });
      },  []);
    return (
        <div>
            {raffle.map((ticket)=>{
                return (
                  <div>
                    {ticket.title}
                    <img src={ticket.imgURL} alt={ticket.desc} />
                    {ticket.number}
                    {ticket.desc}
                    <a href={`/admin/raffle/edit/${ticket._id}`}>Edit</a>
                  </div>
                );
            })}
        </div>
    );
}

export default RaffleList;