import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask"
import Spinner from "../Parts/Spinner";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function UpdateRaffle(props) {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState([]);
  const token = localStorage.getItem("token");
  const [imageURL, setImageURL] = useState("");
  const [picLoading, setPicLoading] = useState(false);
  const [isRedirect, setRedirect] = useState(false);
  const [newRaffle, setNewRaffle] = useState({
    title: "",
    imgURL: imageURL,
    number: "",
    desc: "",
  });

  function handleChange(e) {
    setNewRaffle({ ...newRaffle, [e.target.name]: e.target.value });
  }
  const imageUploadHandler = (e) => {
    e.preventDefault();
    setPicLoading(true);
    const image = e.target.files[0];
    const data = new FormData();
    data.append("file", image);
    axios
      .post("https://mm010221.herokuapp.com/raffle/imageupload", data)
      .then((response) => {
        setPicLoading(false);
        setImageURL(response.data.data);
        setNewRaffle({ ...newRaffle, imgURL: response.data.data });
      });
  };

  console.log(newRaffle);

  function handleSubmit(e) {
    e.preventDefault();

    const headers = {
      headers: { authorization: token },
    };
    axios
      .post(
        "https://mm010221.herokuapp.com/raffle/update/" + id,
        {
          title: newRaffle.title,
          imgURL: newRaffle.imgURL,
          number: newRaffle.number,
          desc: newRaffle.desc,
        },
        headers
      )
      .then((res) => {
        // localStorage.setItem("token", res.data.token);
        setMsg(res.data.newRaffle);
        console.log(res);
        history.push("/admin/rafflelist");
        setNewRaffle({
          ...newRaffle,
          title: "",
          imgURL: "",
          number: "",
          desc: "",
        });
        setImageURL("");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data);
          console.log(err.response);
        }
      });
  }
  function fetchRaffle() {
    axios
      .get("https://mm010221.herokuapp.com/raffle/" + id)
      .then((res) => {
        // let newres = Object.entries(res);
        setNewRaffle(res.data);
        setImageURL(res.data.imgURL);
        setLoading(false);
        if (res.data.length === 0) {
          setRedirect(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchRaffle();
  }, []);
  return (
    <div>
      {isLoading ? <Spinner /> : ""}
      <div className="formWrapper w-full md:w-1/3 mx-auto px-4">
        {msg ? (
          <span className="bg-green-800 text-white px-4 py-2 rounded-md mt-1 block">
            {msg}
          </span>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit}>
          <label htmlFor="title">
            Title
            <input
              type="text"
              name="title"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newRaffle.title}
            />
          </label>
          {errors.title ? (
            <span className="bg-red-400 text-white px-4 py-2 rounded-md mt-1 block">
              {errors.title}
            </span>
          ) : (
            ""
          )}
          {picLoading ? <Spinner /> : null}
          {imageURL ? (
            <img
              src={imageURL}
              alt="your pic"
              style={{ marginTop: "1rem", height: 150 }}
            />
          ) : null}

          <label htmlFor="contained-button-file">
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={imageUploadHandler}
            />
            <div className="bg-black hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              ဓါတ်ပုံတင်မည်
            </div>
          </label>
          <label htmlFor="number">
            Ticket
            <InputMask
              mask="***-***-****-******"
              maskChar=""
              placeholder="ABC-000-EFGH-A12345"
              type="text"
              name="number"
              onChange={handleChange}
              // onKeyPress={handleEnter}
              value={newRaffle.number ? newRaffle.number : ""}
              className=" dark:text-gray-700 pr-12 border-gray-400 border focus:border-gray-500 bg-white appearance-none block w-full focus:outline-none shadow-inner leading-tight rounded-md py-3 px-4"
              autoFocus
            />
          </label>
          <label htmlFor="desc">
            Description
            <input
              type="text"
              name="desc"
              className="w-full border focus:outline-none px-2 rounded-md h-8"
              onChange={handleChange}
              value={newRaffle.desc}
            />
          </label>
          <input
            type="submit"
            value="Update Raffle"
            className="px-6 py-2 mt-2 rounded-md bg-red-400 text-white hover:bg-red-800 cursor-pointer"
          />
        </form>
      </div>
    </div>
  );
}

export default UpdateRaffle;